import React, { useState } from "react";
import { RFQRequestStyled } from "./RFQRequest.styled";
import { Input, Select, Button } from "antd";
import { Table } from "react-bootstrap";
import ConformPopUp from "./ConformPopUp";

const productRows = [
  "OPD Consultation",
  "Dental",
  "Eye",
  "Pharmacy",
  "Lab Test",
  "General Physician [Virtual]",
  "Radiology",
  "Gym",
];
const selectAmountOptions = [
  { value: "Wallet", label: "Set Wallet Amount" },
  { value: "discount", label: "Discount" },
];
const tenureOptions = [
  { value: "1_year", label: "1 Year" },
  { value: "6_month", label: "6 Month" },
  { value: "3_month", label: "3 Month" },
];

const RFQRequestTable = () => {
  const [rowData, setRowData] = useState<any>([]);
  const [counts, setCounts] = useState(Array(productRows.length).fill(0));
  const [limitAmount, setLimitAmount] = useState<number | undefined>();
  const [showConformMsg, setShowConformMsg] = useState(false);
  const [showAddedRows, setShowAddedRows] = useState(false);

  const [selectedAmounts, setSelectedAmounts] = useState(
    Array(productRows.length).fill("")
  );
  const [selectedYear, setSelectedYear] = useState(
    Array(productRows.length).fill("")
  );

  const handleIncrement = (index: any) => {
    setCounts((prevCounts) =>
      prevCounts.map((count, i) => (i === index ? count + 1 : count))
    );
  };

  const handleDecrement = (index: any) => {
    setCounts((prevCounts) =>
      prevCounts.map((count, i) =>
        i === index && count > 1 ? count - 1 : count
      )
    );
  };

  const handleSortAmount = (value: any, index: any) => {
    setSelectedAmounts((prevAmounts) =>
      prevAmounts.map((amount, i) => (i === index ? value : amount))
    );
  };

  const handleSortMonth = (value: any, index: any) => {
    setSelectedYear((prevAmounts) =>
      prevAmounts.map((amount, i) => (i === index ? value : amount))
    );
  };
  const handleAddRow = (index: any) => {
    const product = productRows[index];
    const limitSelect = selectAmountOptions.find(
      (opt) => opt.value === selectedAmounts[index]
    )?.label;
    const tenureSelect = tenureOptions.find(
      (opt) => opt.value === selectedYear[index]
    )?.label;

    const row = {
      product,
      limitType: limitSelect || "",
      limitAmount: limitAmount || "",
      coverage: counts[index],
      tenure: tenureSelect || "",
      added: true,
    };
    if (!rowData.some((r: any) => r.product === product)) {
      setRowData((prev: any) => [...prev, row]);
      console.log("Row added:", row);
    }
  };
  const handleRemoveRow = (index: any) => {
    setRowData((prev: any) => prev.filter((row: any, i: any) => i !== index));
    console.log("Row removed:", index);
  };
  const handletoRequestQuote = () => {
    console.log(rowData, "rowData");
    setShowConformMsg(true);
  };

  return (
    <RFQRequestStyled>
      {!showAddedRows && (
        <div className="ordertable">
          <p>Wellness Subscriptions</p>
          <Table responsive="sm" bordered>
            <thead>
              <tr>
                <th style={{ cursor: "pointer" }}>Products</th>
                <th style={{ cursor: "pointer" }}>Limit</th>
                <th style={{ cursor: "pointer" }}>Coverage</th>
                <th style={{ cursor: "pointer" }}>Tenure</th>
              </tr>
            </thead>
            <tbody>
              {productRows.map((product, index) => (
                <tr key={index}>
                  <td>
                    <Input placeholder={product} readOnly />
                  </td>
                  <td>
                    <Select
                      showSearch
                      placeholder="Sort By Amount"
                      className="me-3"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(value) => handleSortAmount(value, index)}
                      options={selectAmountOptions}
                    />
                    <Input
                      placeholder={`Enter ${selectedAmounts[index]} Amount`}
                      onChange={(e: any) => setLimitAmount(e.target.value)}
                    />
                  </td>
                  <td className="counter-container">
                    <span className="text-label">
                      Employee & Dependent {counts[index]}
                    </span>
                    <Button
                      className="counter-button decrement-button"
                      shape="circle"
                      onClick={() => handleDecrement(index)}
                    >
                      -
                    </Button>
                    <Button
                      className="counter-button increment-button"
                      shape="circle"
                      onClick={() => handleIncrement(index)}
                    >
                      +
                    </Button>
                  </td>
                  <td>
                    <Select
                      showSearch
                      placeholder="Sort By Month"
                      className="me-3"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={tenureOptions}
                      onChange={(value) => handleSortMonth(value, index)}
                    />
                    {rowData.some((row: any) => row.product === product) ? (
                      <button
                        className="btn remove-table-content"
                        onClick={() => handleRemoveRow(index)}
                      >
                        Remove
                      </button>
                    ) : (
                      <button
                        className="btn add-table-content"
                        onClick={() => handleAddRow(index)}
                      >
                        Add
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="table-to-procced-btn">
            <Button
              className="btn add-more-rfq-btn"
              onClick={() => setShowAddedRows(true)}
            >
              Proceed
            </Button>
          </div>
        </div>
      )}

      {showAddedRows && (
        <>
          <p>Your wellness Subcription RFQs</p>
          <div className="show-table-wellness-subcription-div">
            <div className="show-table-wellness-subcription-fleft">
              <Table responsive="sm" bordered className="no-outer-border">
                <thead>
                  <tr className="Subscriptions-th-div">
                    <th>
                      <img
                        src="https://raphacure-public-images.s3.ap-south-1.amazonaws.com/76741-1732697607463.png"
                        alt=""
                      />
                      Subscriptions
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {rowData.map((row: any, index: any) => (
                    <tr key={index}>
                      <td>{row.product}</td>
                      <td>
                        ₹ {row.limitAmount} {row.limitType}
                      </td>
                      <td>Employee + {row.coverage} Dependents</td>
                      <td>{row.tenure}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

            <div className="show-table-wellness-subcription-right">
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Package Name
                </label>
                <input
                  type="text"
                  className="form-control no-box-shadow"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div className="mb-5">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Employees details
                </label>
                <input
                  type="text"
                  className="form-control mb-3"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
                <input
                  type="text"
                  className="form-control mb-3"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div>
                <button
                  className="btn back-more-rfq-btn mb-4"
                  onClick={() => setShowAddedRows(false)}
                >
                  Add More RFQ
                </button>
                <button
                  className="btn add-more-rfq-btn"
                  onClick={handletoRequestQuote}
                >
                  Request For Quote
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      <ConformPopUp
        show={showConformMsg}
        onHide={() => setShowConformMsg(false)}
        conformImg={
          "https://raphacure-public-images.s3.ap-south-1.amazonaws.com/76741-1732700413741.png"
        }
        title={"RFQ Submitted Successfully"}
        subTitle={"We will offer the best prices for you shortly"}
      />
    </RFQRequestStyled>
  );
};

export default RFQRequestTable;
