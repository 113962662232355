import React, { useEffect, useState } from "react";
import { RFQStyled } from "./RFQ.styled";
import { Table } from "react-bootstrap";
import { Input } from "antd";
import { IoIosSearch } from "react-icons/io";
import { getRFQListAPI } from "../../redux/slices/generic/genericService";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SearchInputField from "./SearchInputField";

const RFQ = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { allRFQList } = useSelector((ReduxState: any) => ReduxState.generic);

  useEffect(() => {
    dispatch(getRFQListAPI());
  }, []);

  const handleonFilterName = (searchByText: any) => {
    console.log(searchByText, "searchByText");
  };

  return (
    <RFQStyled>
      <div>
        <h2 className="sectionHeading">RFQ List</h2>
        <div className="left-controls">
          <SearchInputField handleonFilterName={handleonFilterName} />
          <div className="buttons-div">
            <button
              className="btn Package-btn"
              onClick={() => history.push("/rfq/rfqexistinglist")}
            >
              Existing Package
            </button>
            <button
              className="btn Quote-btn"
              onClick={() => history.push("/rfq/rfqrequest")}
            >
              Request for Quote
            </button>
          </div>
        </div>

        <div className="ordertable">
          <Table responsive="sm">
            <thead className="ordertable-thead">
              <tr>
                <th style={{ cursor: "pointer" }}>Type</th>
                <th style={{ cursor: "pointer" }}>RFQ ID</th>
                <th style={{ cursor: "pointer" }}>Submission Date</th>
                <th style={{ cursor: "pointer" }}>Status</th>

                <th>Total Amount</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {allRFQList?.map((item: any) => (
                <tr key={item.id}>
                  <td>{item?.rfq_name}</td>
                  <td>{item.id}</td>
                  <td>
                    {new Date(item.created_at).toLocaleDateString("en-IN", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </td>

                  <td>{item?.status}</td>
                  <td>{item.total_amount}</td>
                  <td>
                    <button className="btn">Edit</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </RFQStyled>
  );
};

export default RFQ;
