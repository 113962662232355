import React, { useState } from "react";
import { RFQCardModuleStyled } from "./RFQCardModule.styled";
import { BsCart2 } from "react-icons/bs";

const RFQCardModule = (props: any) => {
  const {
    details,
    section,
    hideSection,
    onSelectedCardsChange,
    onSelectedRadiologyCardsChange,
    popUpShow,
  } = props;
  const [selectedCards, setSelectedCards] = useState<number[]>([]);

  const handleCardClick = (index: number) => {
    setSelectedCards((prevSelected) => {
      const isSelected = prevSelected.includes(index);
      const updatedSelectedCards = isSelected
        ? prevSelected.filter((i) => i !== index)
        : [...prevSelected, index];
      const updatedSelectedCardDetails = updatedSelectedCards.map(
        (i) => details[i]
      );
      if (section === "Pathology" && onSelectedCardsChange) {
        onSelectedCardsChange(updatedSelectedCardDetails);
      }

      if (section === "Radiology" && onSelectedRadiologyCardsChange) {
        onSelectedRadiologyCardsChange(updatedSelectedCardDetails);
      }

      return updatedSelectedCards;
    });
  };

  const Banner = ({ discount }: { discount: number }) => {
    return (
      <div className="bannerWrapper">
        <div className="rectangle">{discount}% Off</div>
        <div className="triangle"></div>
      </div>
    );
  };

  return (
    <RFQCardModuleStyled>
      <div className="row">
        {details?.map((item: any, index: number) => {
          const isSelected = selectedCards.includes(index);
          return (
            <div
              className={`${
                hideSection === "HideSection" ? "col-lg-6" : "col-lg-4"
              } col-md-6 col-12 mb-4`}
              key={index}
            >
              <div
                className={`RFQExistingList-card-main-div ${
                  isSelected ? "selected" : ""
                }`}
              >
                <div className="d-flex flex-column gap-4">
                  <div className="imgWrapper">
                    <img
                      src="https://raphacure-public-images.s3.ap-south-1.amazonaws.com/76907-1732626138544.png"
                      alt="Background"
                    />
                    {hideSection !== "HideSection" && (
                      <Banner discount={item?.price?.discount_percentage} />
                    )}
                    <div className="textContent">
                      <div>
                        <h2>{item?.service_name}</h2>
                        <h3>{item?.subtitle}</h3>
                      </div>
                      <div>
                        <button className="featureButton">
                          <img
                            src="https://raphacure-public-images.s3.ap-south-1.amazonaws.com/76741-1732554774753.png"
                            alt="Feature Icon"
                          />
                          <p>{section}</p>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-row w-full justify-content-between px-3">
                    <div className="d-flex flex-row logo">
                      <img
                        src="https://raphacure-public-images.s3.ap-south-1.amazonaws.com/76741-1732555185225.png"
                        alt="Reports Logo"
                      />
                      <div>
                        <h3>Report Within</h3>
                        <h4>{item?.reportTime ? item?.reportTime : 0} Hours</h4>
                      </div>
                    </div>
                    <div className="d-flex flex-row logo">
                      <img
                        src="https://raphacure-public-images.s3.ap-south-1.amazonaws.com/76741-1732555146541.png"
                        alt="Lab Tests Logo"
                      />
                      <div>
                        <h3>Includes</h3>
                        <h4>
                          {item?.tests?.length
                            ? `${item.tests.length} Tests${
                                item.tests.length > 1 ? "s" : ""
                              } `
                            : "1 Tests"}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="bottomSec">
                    <div className="prices">
                      <div className="realPrice">
                        ₹{item?.price?.actual_cost}
                      </div>
                      <div className="discountedPrice">
                        ₹{item?.price?.discounted_price}
                      </div>
                    </div>
                    <div className="d-flex">
                      <button
                        className="me-3"
                        onClick={() => props.popUpShow(true, item)}
                      >
                        View
                      </button>
                      <button onClick={() => handleCardClick(index)}>
                        {isSelected ? "Remove" : "Add to RFQ"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </RFQCardModuleStyled>
  );
};

export default RFQCardModule;
