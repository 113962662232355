import styled from "styled-components";

export const ConformPopUpStyled = styled.div`
  text-align: center;
  .conform-msg-title {
    color: #000;
    font-size: 18px;
    font-weight: 500;
  }
  .conform-msg-sub-title {
    color: #000;
    font-size: 14px;
    font-weight: 400;
  }
  .conform-msg-btn {
    margin-top: 1rem;
    border-radius: 2px;
    background: #9747ff;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    width: 70%;
    height: 45px;
  }
`;
