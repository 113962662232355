import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { ReactComponent as Report } from "../../assets/icons/report.svg";
import { ReactComponent as Action } from "../../assets/icons/action1.svg";
import { ReactComponent as Share } from "../../assets/icons/share.svg";
import { ReactComponent as Download } from "../../assets/icons/download.svg";
import { ReactComponent as Call } from "../../assets/icons/call.svg";
import { ReactComponent as Whatsapp } from "../../assets/icons/whats-app.svg";
import { ReactComponent as Gmail } from "../../assets/icons/gmail.svg";
import { BookingStyled } from "./Bookings.styled";
import { useDispatch, useSelector } from "react-redux";
import { getAllBookingListAPI } from "../../redux/slices/dashboard/dashboardService";
import Pagination from "react-bootstrap/Pagination";
import { Button, Dropdown } from "react-bootstrap";
import PatientDetailModal from "../../components/PatientDetailModal/Patientmodal";
import { getPrescriptionsAPI } from "../../redux/slices/orders/orderService";
import PrescriptionModal from "../Doctordashboard/Components/Appointmentorder/PrescriptionModal";
import { FaSearch } from "react-icons/fa";
import * as XLSX from "xlsx";
import {
  getBookingAttachmentImageAPI,
  getBookingSortListAPI,
} from "../../redux/slices/generic/genericService";
import Loader from "./../../components/Common/Loader";
import { BiCommentDetail } from "react-icons/bi";
import { useLocation } from "react-router-dom";

const Bookings = () => {
  let location = useLocation<any>();
  const prescriptionsId = location?.state;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState(
    prescriptionsId?.booking_id || ""
  );
  const [sortConfig, setSortConfig] = useState<any>({
    key: "",
    direction: "asc",
  });
  const dispatch = useDispatch();
  const { bookingsList } = useSelector(
    (ReduxState: any) => ReduxState.dashboard
  );
  const itemsPerPage = 10;

  const allOrder = bookingsList?.bookings || [];
  const [bookingType, setBookingType] = useState<any>("all");
  const [prescriptionModalShow, setPrescriptionModalShow] = useState(false);
  const [selectedPrescription, setSelectedPrescription] = useState<any>(null);
  const [allPrescriptions, setAllPrescriptions] = useState([]);
  const [allReports, setAllReports] = useState([]);
  const [isDateFilterSelected, setIsDateFilterSelected] = useState(false);
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [fromDate, setFromDate] = useState<any>(null);
  const [toDate, setToDate] = useState<any>(null);
  const [selectedSortKey, setSelectedSortKey] = useState("");

  console.log(fromDate, toDate, selectedCheckbox, "fromDate");

  const { allBookingSortList } = useSelector(
    (ReduxState: any) => ReduxState.generic
  );
  const BookingSortList = allBookingSortList?.bookingStatuses;

  const getPrescriptions = async () => {
    const res1 = await dispatch(getPrescriptionsAPI("prescriptions"));
    const resJson1 = JSON.parse(JSON.stringify(res1));
    setAllPrescriptions(resJson1?.payload?.data?.bookingAttachments);

    const res2 = await dispatch(getPrescriptionsAPI("reports"));
    const resJson2 = JSON.parse(JSON.stringify(res2));

    setAllReports(resJson2?.payload?.data?.bookingAttachments);
  };

  useEffect(() => {
    getPrescriptions();
  }, []);
  const handlePrescriptionClick = (bookingId: any, id: any) => {
    const matchedPrescription = allPrescriptions.find(
      (prescription: any) => prescription.booking_id === bookingId
    );
    const patientObj = {
      ...bookingId,
      ...bookingId?.user,
    };
    setSelectedPrescription(matchedPrescription);
    setPrescriptionModalShow(true);
    setSelectedPatientID(id);
    setSelectedPatient(patientObj);
  };

  const handleReportClick = (bookingId: any) => {
    const matchedReport = allReports.find(
      (report: any) => report.booking_id === bookingId
    );
    setSelectedPrescription(matchedReport);
    setPrescriptionModalShow(true);
  };

  useEffect(() => {
    const currUrl = window.location.href;
    const urlParams = new URLSearchParams(window.location.search);
    const statusParam = urlParams.get("status");
    console.log("Status Param:", statusParam);
    setBookingType(statusParam);
  }, []);

  const formatDate = (timestamp: any) => {
    const date = new Date(parseInt(timestamp, 10));
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };
  useEffect(() => {}, [bookingsList]);

  const filteredOrders = allOrder.filter((item: any) => {
    const fullName = `${item?.user?.first_name} ${item?.user?.last_name}`;
    return (
      fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item?.id?.toString().includes(searchQuery)
    );
  });
  // Sorting logic
  const sortedOrders = filteredOrders.sort((a: any, b: any) => {
    if (!sortConfig.key) return 0;

    const key = sortConfig.key;
    const order = sortConfig.direction === "asc" ? 1 : -1;

    const valueA =
      key === "name" ? `${a.user.first_name} ${a.user.last_name}` : a[key];
    const valueB =
      key === "name" ? `${b.user.first_name} ${b.user.last_name}` : b[key];

    if (valueA < valueB) return -1 * order;
    if (valueA > valueB) return 1 * order;
    return 0;
  });

  // Filter orders based on bookingType
  const filteredByBookingType =
    bookingType === "cancelled"
      ? sortedOrders.filter((item: any) => item.status === "cancelled")
      : sortedOrders;

  // Calculate the current page's data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentOrders = filteredByBookingType.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedPatientID, setSelectedPatientID] = useState(null);
  const [selectedPatientReport, setSelectedPatientReport] = useState(null);
  const [lgShow, setLgShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleModalOpen = (patient: any, id: any) => {
    const patientObj = {
      ...patient,
      ...patient?.user,
    };
    setSelectedPatient(patientObj);
    setSelectedPatientID(id);
    setLgShow(true);
  };

  const handleModalClose = () => {
    setLgShow(false);
    setSelectedPatient(null);
  };

  const totalPages = Math.ceil(filteredByBookingType.length / itemsPerPage);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  const handleSortChange = async (key: any) => {
    console.log(key, "key");

    setIsLoading(true);
    setSortConfig((prevSort: any) => ({
      key,
      direction:
        prevSort.key === key && prevSort.direction === "asc" ? "desc" : "asc",
    }));

    setSelectedSortKey(key);

    await dispatch(
      getAllBookingListAPI({
        searchText: "",
        count: 100,
        status: key || "",
        dateType:
          isDateFilterSelected && selectedCheckbox
            ? `"${selectedCheckbox}"`
            : null,
        searchFromDate:
          isDateFilterSelected && fromDate ? `"${fromDate}"` : null,
        searchToDate: isDateFilterSelected && toDate ? `"${toDate}"` : null,
      })
    );
    setIsLoading(false);
  };

  const handleShare = (platform: string, patient: any) => {
    const url = window.location.href; // Current URL
    const message = `Check out this appointment details for ${patient?.first_name}:\n${url}`;

    switch (platform) {
      case "whatsapp":
        window.open(
          `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`,
          "_blank"
        );
        break;
      case "email":
        window.open(
          `mailto:?subject=Appointment Details&body=${encodeURIComponent(
            message
          )}`,
          "_self"
        );
        break;
      default:
        break;
    }
  };

  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch("https://website.com/file.pdf").then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);

        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "SamplePDF.pdf";
        alink.click();
      });
    });
  };

  const handleDownload = () => {
    const exportData = currentOrders.map((item: any) => ({
      "Booking ID": item?.id,
      Name: `${item?.user?.first_name} ${item?.user?.last_name}`,
      Age: item?.user?.age,
      Gender: item?.user?.gender || "N/A",
      "Scheduled Date & Time": formatDate(item.collection_1_date),
      "Visit Type": item.type || "N/A",
      Service: item.notes || "N/A",
      Status: item.status || "N/A",
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Booking Reports");
    XLSX.writeFile(workbook, "Booking_Reports.xlsx");
  };

  const getPackageDetails = async () => {
    await dispatch(getBookingSortListAPI());
  };

  useEffect(() => {
    getPackageDetails();
    dispatch(
      getAllBookingListAPI({
        searchText: searchQuery || "",
        count: 1000,
        status: selectedSortKey || "", // Use the stored sort key
        dateType:
          isDateFilterSelected && selectedCheckbox
            ? `"${selectedCheckbox}"`
            : null,
        searchFromDate:
          isDateFilterSelected && fromDate ? `"${fromDate}"` : null,
        searchToDate: isDateFilterSelected && toDate ? `"${toDate}"` : null,
      })
    );
  }, [searchQuery]);

  const handleFilterClick = (e: any) => {
    if (e.target.tagName !== "INPUT") {
      e.currentTarget.querySelector("input").click();
    }
  };

  const handleDateFilterChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = e.target.checked;
    setIsDateFilterSelected(isChecked);

    // Trigger API call with the current state, including the sort key
    if (isChecked) {
      await dispatch(
        getAllBookingListAPI({
          searchText: searchQuery || "",
          count: 100,
          status: selectedSortKey || "", // Use the stored sort key
          dateType:
            isDateFilterSelected && selectedCheckbox
              ? `"${selectedCheckbox}"`
              : null,
          searchFromDate:
            isDateFilterSelected && fromDate ? `"${fromDate}"` : null,
          searchToDate: isDateFilterSelected && toDate ? `"${toDate}"` : null,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      getAllBookingListAPI({
        searchText: searchQuery || "",
        count: 100,
        status: selectedSortKey || "", // Use the stored sort key
        dateType:
          isDateFilterSelected && selectedCheckbox
            ? `"${selectedCheckbox}"`
            : null,
        searchFromDate:
          isDateFilterSelected && fromDate ? `"${fromDate}"` : null,
        searchToDate: isDateFilterSelected && toDate ? `"${toDate}"` : null,
      })
    );
  }, [isDateFilterSelected, fromDate, toDate, selectedCheckbox]);

  const handleCheckboxChange = (e: any) => {
    const { id } = e.target;
    setSelectedCheckbox(id === selectedCheckbox ? null : id);
  };

  const getPaginationItems = () => {
    const paginationItems = [];
    const maxPageNumbersToShow = 10;
    const half = Math.floor(maxPageNumbersToShow / 2);

    let startPage = Math.max(1, currentPage - half);
    let endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1);
    if (endPage - startPage < maxPageNumbersToShow - 1) {
      startPage = Math.max(1, endPage - (maxPageNumbersToShow - 1));
    }
    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    if (startPage > 1) {
      paginationItems.unshift(
        <Pagination.Ellipsis
          key="ellipsis-start"
          onClick={() => handlePageChange(startPage - 1)}
        />
      );
    }
    if (endPage < totalPages) {
      paginationItems.push(
        <Pagination.Ellipsis
          key="ellipsis-end"
          onClick={() => handlePageChange(endPage + 1)}
        />
      );
    }

    return paginationItems;
  };

  return (
    <BookingStyled>
      {isLoading && <Loader />}
      <div className=" booking-main-div">
        <h2 className="sectionHeading">Bookings</h2>
        <div className="searcharfilter">
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search Patient Name or Serial No"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <FaSearch />
          </div>
          <div className="filterplace">
            <button className="btn download-btn" onClick={handleDownload}>
              Download
            </button>
            <div className="sort-select">
              <select
                onChange={(e) => handleSortChange(e.target.value)}
                className="shadow-none"
              >
                <option value="">Sort By</option>

                {BookingSortList?.map((item: any, index: number) => (
                  <option key={index} value={item?.id}>
                    {item?.name}
                  </option>
                ))}
                {/* <option value="name">Name</option>
                <option value="collection_1_date">Date & Time</option>
                <option value="id">Serial No</option> */}
              </select>
            </div>
            <div
              className="date-select"
              onClick={(e: any) => {
                if (e.target.tagName !== "INPUT") {
                  e.currentTarget.querySelector("input").click();
                }
              }}
              style={{ cursor: "pointer" }}
            >
              <input
                type="checkbox"
                onChange={handleDateFilterChange}
                checked={isDateFilterSelected}
              />
              <p>Filter by Date (Export)</p>
            </div>
          </div>
        </div>
        {isDateFilterSelected && (
          <div className="d-flex align-items-center">
            <div className="checkbox-container">
              <div className="content">
                <label className="checkBox">
                  <input
                    type="checkbox"
                    id="scheduled"
                    checked={selectedCheckbox === "scheduled"}
                    onChange={handleCheckboxChange}
                  />
                  <div className="transition"></div>
                </label>
                <label htmlFor="ch1">Requested Date</label>
              </div>
              <div className="content">
                <label className="checkBox">
                  <input
                    type="checkbox"
                    id="requested"
                    checked={selectedCheckbox === "requested"}
                    onChange={handleCheckboxChange}
                  />
                  <div className="transition"></div>
                </label>
                <label htmlFor="ch2">Scheduled Date</label>
              </div>
            </div>

            <div className="checkbox-container">
              <label>From</label>
              <input
                type="date"
                name="fromDate"
                id="fromDate"
                value={fromDate || null}
                onChange={(e: any) => setFromDate(e.target.value)}
              />
              <label>To</label>
              <input
                type="date"
                name="toDate"
                id="toDate"
                value={toDate || null}
                onChange={(e: any) => setToDate(e.target.value)}
                // onChange={(e) => handleSortChange(e.target.value)}
              />
            </div>
          </div>
        )}
        <div className="ordertable">
          <Table responsive="sm">
            <thead>
              <tr>
                <th
                  onClick={() => handleSortChange("id")}
                  style={{ cursor: "pointer" }}
                >
                  Booking ID
                </th>
                <th
                  onClick={() => handleSortChange("name")}
                  style={{ cursor: "pointer" }}
                >
                  Name
                </th>
                <th
                  onClick={() => handleSortChange("user.age")}
                  style={{ cursor: "pointer" }}
                >
                  Age
                </th>
                <th style={{ cursor: "pointer" }}>Gender</th>
                {/* Gender */}
                <th
                  onClick={() => handleSortChange("collection_1_date")}
                  style={{ cursor: "pointer" }}
                >
                  Scheduled Date & Time
                </th>
                <th>Visit Type</th>
                <th>Service</th>
                <th>Status</th>
                {/* <th className="action-report">Report</th> */}
                <th className="action-report">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentOrders.map((item: any, index: any) => (
                <tr key={index}>
                  <td>{item?.id}</td>
                  <td
                    style={{ color: "purple", cursor: "pointer" }}
                    onClick={(e: any) => handleModalOpen(item, item.id)}
                  >
                    {item?.user?.first_name} {item?.user?.last_name}
                  </td>
                  <td>{item?.user?.age}</td>
                  <td>{item?.user?.gender || "N/A"}</td>
                  <td>{`${formatDate(item.collection_1_date)}`}</td>
                  <td>{item.notes || "N/A"}</td>
                  <td>{item.type || "N/A"}</td>
                  <td>{item.status || "N/A"}</td>
                  {/* <td className="action-report">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => handleReportClick(item.id)} // Call the function here
                    >
                      <Report title="Report" />
                    </span>
                  </td> */}
                  <td className="actions">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => handlePrescriptionClick(item, item.id)}
                    >
                      <BiCommentDetail title="Prescription" className="fs-5" />
                    </span>
                    <span className="icon-space"></span>
                    <Dropdown>
                      <Dropdown.Toggle
                        as={Share}
                        id="dropdown-custom-components"
                      />

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleShare("whatsapp", item?.user)}
                        >
                          <Whatsapp /> WhatsApp
                        </Dropdown.Item>

                        <Dropdown.Item
                          onClick={() => handleShare("email", item?.user)}
                        >
                          <Gmail /> Email
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <span className="icon-space"></span>
                    {/* <Download onClick={onButtonClick} /> */}
                    {/* <span className="icon-space"></span> */}
                    <a href={`tel:${item?.user?.phone}`}>
                      <Call />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="pagination">
            <Pagination className="custom-pagination">
              <Pagination.First onClick={() => handlePageChange(1)} />
              <Pagination.Prev
                onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
              />
              {getPaginationItems()}
              <Pagination.Next
                onClick={() =>
                  handlePageChange(Math.min(totalPages, currentPage + 1))
                }
              />
              <Pagination.Last onClick={() => handlePageChange(totalPages)} />
            </Pagination>
          </div>
        </div>

        <PatientDetailModal
          prescriptionsId={prescriptionsId}
          show={lgShow}
          onHide={handleModalClose}
          patient={selectedPatient}
          bookingId={selectedPatientID}
          bookingReport={allReports}
        />

        <PrescriptionModal
          show={prescriptionModalShow}
          onHide={() => setPrescriptionModalShow(false)}
          prescriptionData={selectedPrescription}
          bookingId={selectedPatientID}
          patient={selectedPatient}
        />
      </div>
    </BookingStyled>
  );
};

export default Bookings;
