import React from "react";
import { Modal } from "react-bootstrap";
import { ConformPopUpStyled } from "./ConformPopUp.styled";
import { useHistory } from "react-router-dom";

const ConformPopUp = (props: any) => {
  const history = useHistory();
  const { conformImg, title, subTitle, path } = props;
  return (
    <div>
      <Modal {...props} backdrop="static" keyboard={false}>
        <ConformPopUpStyled>
          <img src={conformImg} alt="" />
          <p className="conform-msg-title">{title}</p>
          <p className="conform-msg-sub-title">{subTitle}</p>
          <button
            className="btn conform-msg-btn"
            onClick={() => history.push(path)}
          >
            OK
          </button>
        </ConformPopUpStyled>
      </Modal>
    </div>
  );
};

export default ConformPopUp;
