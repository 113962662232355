import styled from "styled-components";

export const RFQStyled = styled.div`
  padding: 0px 20px;
  .left-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  .search-bar {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .buttons-div {
    display: flex;
    gap: 25px;
  }
  .ant-input-affix-wrapper {
    width: 20rem;
  }
  .Package-btn {
    border-radius: 3px;
    border: 1px solid #a3a3a3;
    background: #fff;
    color: #686868;
    font-size: 12px;
    font-weight: 400;
    height: 3rem;
  }
  .Quote-btn {
    border-radius: 2px;
    background: #9747ff;
    box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.1);
    color: #f6f9fd;
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-weight: 500;
  }

  .ordertable {
    margin-top: 12px;
    width: 100%;
    overflow-x: scroll;
  }
  .ordertable Table {
    --bs-table-bg: transparent !important;
    border-radius: 5px;
    border: 1px solid #d6cece;
  }
  .ordertable thead {
    border-radius: 5px 5px 0px 0px;
    background: #d3edfc;
  }
  .ordertable th {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-wrap: nowrap;
  }
  .ordertable td {
    color: #808080;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-wrap: nowrap;
  }
  .ordertable tr {
    border: 1px solid #d6cece;
  }
`;
