import styled from "styled-components";

export const RFQServiceModuleStyled = styled.div`
  .Continue-Service-div {
    width: 100%;
    display: flex;
    padding: 50px 0px;
    gap: 2rem;
  }
  .Continue-Service-left-div {
    width: 70%;
  }
  .Continue-Service-right-div {
    width: 30%;
  }
  .Continue-Service-sub-right-div {
    border-radius: 4px;
    background: #fff;
    box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 688px;
    padding: 20px 20px;
  }

  .excel-upload-div {
    display: flex;
    align-items: center;
    gap: 4rem;
  }
  .excel-upload-div button {
    border-radius: 2px;
    background: #fff;
    box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.1);
  }
  .ant-input-affix-wrapper > .ant-input:not(textarea) {
    height: 2rem;
  }
  .Continue-Service-Proceed-btn {
    border-radius: 4px;
    background: #9747ff;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
  }
  .Continue-Service-back-btn {
    border-radius: 4px;
    border: 1px solid #9747ff;
    color: #9747ff;
    font-size: 14px;
    font-weight: 600;
  }
  .Continue-Service-Proceed-btn-div {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
  }
  .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    padding: 0px 10px;
    gap: 15rem;
    border-radius: 2px;
    background: #fff;
    height: 53px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #9747ff;
  }
  .Service-tabs-sec {
    margin-top: 2rem;
  }
  .lab-test-list-title {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.8;
  }
  .lab-test-list-sub-title {
    color: #808080;
    font-size: 11px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.6px;
  }
  .pagination-div {
    display: flex;
    justify-content: center;
    margin: 3rem;
  }
`;
