import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getEyeCategoriesAPI,
  getEyeHospitalsAPI,
  getAmbulanceList,
  getCategoriesAPI,
  getGymVendorsAPI,
  getUniversalSearchData,
  getBookingSortListAPI,
  getReportOrderAPI,
  getClintUserWalletAmountDetailsAPI,
  getClintWalletDetailsAPI,
  getClintUserWalletDetailsAPI,
  getAllPrescriptionsAPI,
  getEmployeeCountAPI,
  getRFQListAPI,
  getRFQTestsListAPI,
} from "./genericService";

export interface CtmriState {
  loading: boolean;
  error: any;
  allEyeCareHospitalsList: {};
  allEyeCareCategoriesList: {};
  allAmbulanceList: {};
  allGymCategoriesList: {};
  allGymVendorsList: {};
  universalSearchResults: [];
  allBookingSortList: [];
  allClintUserWalletAmountDetailsList: [];
  allReportOrderList: [];
  allClintWalletDetailsList: [];
  allClintUserWalletDetailsList: [];
  allPrescriptionsList: [];
  allEmployeeCountList: [];
  allRFQList: [];
  allRFQTestsList: [];
}
const initialState: CtmriState = {
  loading: false,
  error: null,
  allEyeCareCategoriesList: {},
  allEyeCareHospitalsList: {},
  allAmbulanceList: {},
  allGymCategoriesList: {},
  allGymVendorsList: {},
  universalSearchResults: [],
  allBookingSortList: [],
  allReportOrderList: [],
  allClintWalletDetailsList: [],
  allClintUserWalletDetailsList: [],
  allClintUserWalletAmountDetailsList: [],
  allPrescriptionsList: [],
  allEmployeeCountList: [],
  allRFQList: [],
  allRFQTestsList: [],
};

export const ctmriSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (state: CtmriState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateErrorMessage: (state: CtmriState, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    updateAllAmbulanceList: (state: CtmriState, action: PayloadAction<any>) => {
      state.allAmbulanceList = action.payload;
    },
    updateUniversalSearchResults: (
      state: CtmriState,
      action: PayloadAction<any>
    ) => {
      state.universalSearchResults = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(getEyeCategoriesAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getEyeCategoriesAPI.fulfilled,
      (state: CtmriState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allEyeCareCategoriesList = action.payload.data;
      }
    );
    builder.addCase(
      getEyeCategoriesAPI.rejected,
      (state: CtmriState, action: any) => {
        console.log({ action });
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    //scanDetails
    builder.addCase(getEyeHospitalsAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getEyeHospitalsAPI.fulfilled,
      (state: CtmriState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allEyeCareHospitalsList = action.payload.data;
      }
    );
    builder.addCase(
      getEyeHospitalsAPI.rejected,
      (state: CtmriState, action: any) => {
        console.log({ action });
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    //getAmbulanceList
    builder.addCase(getAmbulanceList.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAmbulanceList.fulfilled,
      (state: CtmriState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allAmbulanceList = action.payload.data;
      }
    );
    builder.addCase(
      getAmbulanceList.rejected,
      (state: CtmriState, action: any) => {
        console.log({ action });
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
      }
    );

    builder.addCase(getCategoriesAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getCategoriesAPI.fulfilled,
      (state: CtmriState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allGymCategoriesList = action.payload.data;
      }
    );
    builder.addCase(
      getCategoriesAPI.rejected,
      (state: CtmriState, action: any) => {
        console.log({ action });
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
      }
    );

    builder.addCase(getGymVendorsAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getGymVendorsAPI.fulfilled,
      (state: CtmriState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allGymVendorsList = action.payload.data;
      }
    );
    builder.addCase(
      getGymVendorsAPI.rejected,
      (state: CtmriState, action: any) => {
        console.log({ action });
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    // Filter BookingSortListAPI
    builder.addCase(getBookingSortListAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getBookingSortListAPI.fulfilled,
      (state: CtmriState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allBookingSortList = action.payload.data;
      }
    );
    builder.addCase(
      getBookingSortListAPI.rejected,
      (state: CtmriState, action: any) => {
        console.log({ action });
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
        state.allBookingSortList = [];
      }
    );
    // getRFQListAPI
    builder.addCase(getRFQListAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getRFQListAPI.fulfilled,
      (state: CtmriState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allRFQList = action.payload.rfqs;
      }
    );
    builder.addCase(
      getRFQListAPI.rejected,
      (state: CtmriState, action: any) => {
        console.log({ action });
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
        state.allRFQList = [];
      }
    );
    // grtRFQTestsListAPI
    builder.addCase(getRFQTestsListAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getRFQTestsListAPI.fulfilled,
      (state: CtmriState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allRFQTestsList = action.payload.services;
      }
    );
    builder.addCase(
      getRFQTestsListAPI.rejected,
      (state: CtmriState, action: any) => {
        console.log({ action });
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
        state.allRFQTestsList = [];
      }
    );

    // getReportOrderAPI
    builder.addCase(getReportOrderAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getReportOrderAPI.fulfilled,
      (state: CtmriState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allReportOrderList = action.payload.data;
      }
    );
    builder.addCase(
      getReportOrderAPI.rejected,
      (state: CtmriState, action: any) => {
        console.log({ action });
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
        state.allReportOrderList = [];
      }
    );
    // getClintUserWalletAmountDetailsAPI
    builder.addCase(
      getClintUserWalletAmountDetailsAPI.pending,
      (state: any) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addCase(
      getClintUserWalletAmountDetailsAPI.fulfilled,
      (state: CtmriState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allClintUserWalletDetailsList = action.payload.data;
      }
    );
    builder.addCase(
      getClintUserWalletAmountDetailsAPI.rejected,
      (state: CtmriState, action: any) => {
        console.log({ action });
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
        state.allClintUserWalletDetailsList = [];
      }
    );
    // getClintWalletDetailsAPI
    builder.addCase(getClintWalletDetailsAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getClintWalletDetailsAPI.fulfilled,
      (state: CtmriState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allClintWalletDetailsList = action.payload.data;
      }
    );
    builder.addCase(
      getClintWalletDetailsAPI.rejected,
      (state: CtmriState, action: any) => {
        console.log({ action });
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
        state.allClintWalletDetailsList = [];
      }
    );
    // getAllPrescriptionsAPI
    builder.addCase(getAllPrescriptionsAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllPrescriptionsAPI.fulfilled,
      (state: CtmriState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allPrescriptionsList = action.payload.data;
      }
    );
    builder.addCase(
      getAllPrescriptionsAPI.rejected,
      (state: CtmriState, action: any) => {
        console.log({ action });
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
        state.allPrescriptionsList = [];
      }
    );
    // getClintUserWalletDetailsAPI
    builder.addCase(getClintUserWalletDetailsAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getClintUserWalletDetailsAPI.fulfilled,
      (state: CtmriState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allClintUserWalletDetailsList = action.payload.data;
      }
    );
    builder.addCase(
      getClintUserWalletDetailsAPI.rejected,
      (state: CtmriState, action: any) => {
        console.log({ action });
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
        state.allClintUserWalletDetailsList = [];
      }
    );
    // getEmployeeCountAPI
    builder.addCase(getEmployeeCountAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getEmployeeCountAPI.fulfilled,
      (state: CtmriState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allEmployeeCountList = action.payload.data;
      }
    );
    builder.addCase(
      getEmployeeCountAPI.rejected,
      (state: CtmriState, action: any) => {
        console.log({ action });
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
        state.allEmployeeCountList = [];
      }
    );
    //getUniversalSearchData
    builder.addCase(getUniversalSearchData.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getUniversalSearchData.fulfilled,
      (state: CtmriState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.universalSearchResults = action.payload.data?.results || [];
      }
    );
    builder.addCase(
      getUniversalSearchData.rejected,
      (state: CtmriState, action: any) => {
        console.log({ action });
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
  },
});

export const {
  setLoading,
  updateAllAmbulanceList,
  updateUniversalSearchResults,
} = ctmriSlice.actions;

export default ctmriSlice.reducer;
