import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { post, put, get, patch } from "../../../library/Requests/helpers";

import {
  getEyeHospitalsQuery,
  getEyeCategoriesQuery,
  getGymCategoriesQuery,
  getGymVendorsQuery,
  clintWalletDetailsAPIQuery,
  clintWalletUserDetailsAPIQuery,
  getAllPrescriptionsAPIQuery,
} from "../../../Scenes/graphqlConfig";

export const getEyeCategoriesAPI = createAsyncThunk(
  "auth/getEyeCategoriesAPI",
  async (body: any) =>
    await post(
      `${SERVER_IP}/graphql`,
      JSON.stringify(getEyeCategoriesQuery(body))
    )
);
export const getEyeHospitalsAPI = createAsyncThunk(
  "auth/getEyeHospitalsAPI",
  async (body: any) =>
    await post(
      `${SERVER_IP}/graphql`,
      JSON.stringify(getEyeHospitalsQuery(body))
    )
);

export const getAmbulanceList = createAsyncThunk(
  "auth/getAmbulanceList",
  async (body: any) => await patch(`${SERVER_IP}/api/v1/test/ambulance`, body)
);
export const getGymVendorsAPI = createAsyncThunk(
  "auth/getGymVendorsAPI",
  async (body: any) =>
    await post(`${SERVER_IP}/graphql`, JSON.stringify(getGymVendorsQuery(body)))
);
export const getCategoriesAPI = createAsyncThunk(
  "auth/getCategoriesAPI",
  async (body: any) =>
    await post(
      `${SERVER_IP}/graphql`,
      JSON.stringify(getGymCategoriesQuery(body))
    )
);
export const getUniversalSearchData = createAsyncThunk(
  "auth/getUniversalSearchData",
  async (body: any) => await post(`${SERVER_IP}/api/v1/config/search`, body)
);

export const getBookingSortListAPI = createAsyncThunk(
  "auth/getBookingSortListAPI",
  async () => await get(`${SERVER_IP}/api/v1/config/bookingstatuses`)
);

export const getReportOrderAPI = createAsyncThunk(
  "auth/getReportOrderAPI",
  async (bookingId: any) =>
    await get(`${SERVER_IP}/api/v1/booking/${bookingId}/additional`)
);
export const getClintWalletDetailsAPI = createAsyncThunk(
  "auth/getClintWalletDetailsAPI",
  async (walletId: any) =>
    await post(
      `${SERVER_IP}/graphql`,
      JSON.stringify(clintWalletDetailsAPIQuery(walletId))
    )
);
export const getClintUserWalletDetailsAPI = createAsyncThunk(
  "auth/getClintUserWalletDetailsAPI",
  async (walletId: any) =>
    await post(
      `${SERVER_IP}/graphql`,
      JSON.stringify(clintWalletUserDetailsAPIQuery(walletId))
    )
);
export const getClintUserWalletAmountDetailsAPI = createAsyncThunk(
  "auth/getClintUserWalletAmountDetailsAPI",
  async (userId: any) => await get(`${SERVER_IP}/api/v1/wallet/user/${userId}`)
);
export const getAllPrescriptionsAPI = createAsyncThunk(
  "auth/getAllPrescriptionsAPI",
  async (body: any) =>
    await post(
      `${SERVER_IP}/graphql`,
      JSON.stringify(
        getAllPrescriptionsAPIQuery(body.walletId, body.searchText)
      )
    )
);
export const signinAsUserAPI = createAsyncThunk(
  "auth/signinAsUserAPI",
  async (body: any) =>
    await patch(`${SERVER_IP}/api/v1/auth/signinasuser`, body)
);

export const getBookingAttachmentImageAPI = createAsyncThunk(
  "auth/getBookingAttachmentImageAPI",
  async (userId: any) =>
    await get(`${SERVER_IP}/api/v1/booking/attachment/${userId}/signed`)
);
export const getReBookingAttachmentAPI = createAsyncThunk(
  "auth/getReBookingAttachmentAPI",
  async (reUserID: any) =>
    await patch(`${SERVER_IP}/api/v1/attachment/${reUserID}/book`)
);

export const getEmployeeCountAPI = createAsyncThunk(
  "auth/getEmployeeCountAPI",
  async (empID: any) =>
    await get(`${SERVER_IP}/api/v1/client/count?clientId=${empID}`)
);

export const getRFQListAPI = createAsyncThunk(
  "auth/getRFQListAPI",
  async () => await get(`${SERVER_IP}/api/v1/rfq`)
);
export const getRFQTestsListAPI = createAsyncThunk(
  "auth/getRFQTestsListAPI",
  async () => await get(`${SERVER_IP}/api/v1/rfq/service`)
);

export const createNewRFQAPI = createAsyncThunk(
  "Profile/createNewRFQAPI",
  async (newRFQ: any) => await post(`${SERVER_IP}/api/v1/rfq`, newRFQ)
);
